.app-login-main-content{
    right: 5% !important;
    display: block;
}

.app-login-container {
    max-width: none !important;
    width: 100% !important;
}

.app-login-main-content{
    align-content: right !important;
}
 
.app-logo-content{
    width: 100%;
}