.alerta {
  padding: 10px;
  animation: vibrar 0.4s;
  animation-iteration-count: infinite;
  margin-top: 10px;
}
.hoverAlertas {
  cursor: pointer;
}
.alerta button {
  padding: 4px 10px;
}
@keyframes vibrar {
  0% {
    transform: translateX(0px);
  }
  50%{
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0px);
  }
}
